<template>
  <div class="studentStar">
    <div class="home_title">
      <span>学生风采</span>
    </div>

    <div class="cont">
      <div class="cont_top">
        <div class="cover_file" v-if="studentData[currentIndex].styleVideo">
          <video-player
            class="video-player-box a-fade-in"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
          ></video-player>
          <img
            class="open_icon"
            @click="openViedo"
            v-if="openIcon"
            src="../../assets/imgs/video_big_icon.png"
            alt
          />
        </div>
        <div class="cover_file" v-else>
          <img
            class="top_cover_img a-fade-in"
            :src="QiNiuPath + studentData[currentIndex].styleImg"
            alt
          />
        </div>
        <div class="student_desc">
          <p class="desc_txt">{{studentData[currentIndex].personalIntroduction}}</p>
        </div>
        <div class="student_infor">
          <h2 class="name">{{studentData[currentIndex].fullName}}</h2>
          <ul class="labels">
            <li
              class="label"
              v-for="(item, index) in studentData[currentIndex].personalTitleList"
              :key="index"
            >{{item}}</li>
          </ul>
        </div>
      </div>
      <div class="cont_bottom">
        <ul class="students">
          <li
            class="s_li"
            :class="currentIndex == index? 'li_transform': ''"
            v-for="(item, index) in studentData.slice(0,6)"
            :key="item.id"
            @mouseenter="checkStar(index)"
          >
            <img
              class="student_img"
              :class="currentIndex == index? 'check_active': ''"
              :src="QiNiuPath + item.styleAvatar"
              alt
            />
            <p class="s_name" :class="currentIndex == index?'check_txt': '' ">{{item.fullName}}</p>
            <!-- <div class="trigon" :class="currentIndex == index?'check_trigon': '' "></div> -->
            <img
              class="trigon"
              :class="currentIndex == index?'check_trigon': '' "
              src="../../assets/imgs/trigon.png"
              alt
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      playerOptions: {}, // video.js 配置,
      currentIndex: 0, // 当前下标
      openIcon: true, //自定义视频播放icon
      isVideo: false,
    };
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
    // video.js 配置
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  props: {
    studentData: Array,
  },
  created() {
    this.playerOptions = {
      // video.js 配置
      // videojs options
      muted: true,
      language: "en",
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [
        {
          type: "video/mp4",
          src: this.QiNiuPath + this.studentData[this.currentIndex].styleVideo,
        },
      ],
      poster: this.QiNiuPath + this.studentData[this.currentIndex].styleImg,
      width: "666px",
      height: "375px",
      muted: false
    };
  },
  methods: {
    checkStar(e) {
      // 判断选择是否一样，并且是否有视频
      if (this.currentIndex != e && this.studentData[e].styleVideo) {
        this.currentIndex = e;
        this.playerOptions.sources = [
          {
            type: "video/mp4",
            src: this.QiNiuPath + this.studentData[e].styleVideo,
          },
        ];
        this.playerOptions.poster =
          this.QiNiuPath + this.studentData[e].styleImg;
      } else {
        this.currentIndex = e;
      }

      this.openIcon = true;
    },

    onPlayerPlay(player) {
      this.openIcon = false;
    },
    onPlayerPause(player) {
      this.openIcon = true;
    },
    openViedo() {
      this.player.play();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";

.home_title {
  @include sc(40px);
  font-weight: bold;
  text-align: center;
  margin: 0 auto 50px auto;
  width: 1200px;
  position: relative;
  &::after {
    content: "";
    display: block;
    @include wh(70px, 3px);
    background: #5d5aed;
    margin: 7px auto 0 auto;
  }
}

.studentStar {
  margin-bottom: 88px;
  .cont {
    width: 1200px;
    margin: auto;
    .cont_top {
      position: relative;
      height: 398px;
      margin-bottom: 55px;
      .cover_file {
        @include wh(666px, 375px, 14px);
        position: relative;
        margin-left: 20px;
        border-radius: 14px;
        overflow: hidden;
        background: #fff;
        .top_cover_img {
          @include wh(666px, 375px);
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
          overflow: hidden;
          transition: transform 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
        }
        .open_icon {
          @include wh(81px, 69px);
          @include ascenter();
          transition: transform 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .student_desc {
        @include wh(1200px, 250px);
        background: url("../../assets/imgs/student_bg.png") no-repeat center;
        background-size: cover;
        position: absolute;
        top: 145px;
        z-index: -1;
        border-radius: 20px;
        overflow: hidden;
        .desc_txt {
          @include sc(20px, #fff);
          @include mtxtflow(6);
          width: 456px;
          position: absolute;
          right: 20px;
          top: 36px;
          word-break: break-all;
        }
      }
      .student_infor {
        position: absolute;
        top: 0px;
        left: 727px;
        .name {
          @include sc(32px, #333a48);
          @include txtflow();
          max-width: 440px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .labels {
          display: flex;
          .label {
            @include sc(16px, #5d5aed);
            padding: 7px 15px;
            border-radius: 18px;
            border: 1px solid #5d5aed;
            margin-right: 12px;
            transition: all 0.3s ease;
            @include txtflow();
            max-width: 206px;
            &:last-child {
              margin-right: 0px;
            }
            &:hover {
              transform: translateY(-2px);
            }
          }
        }
      }
    }
    .cont_bottom {
      .students {
        text-align: center;
        display: flex;
        .s_li {
          margin-right: 90px;
          position: relative;
          transition: all 0.2s ease;
          &:last-child {
            margin-right: 0px;
          }
          .student_img {
            @include wh(90px, 90px);
            border-radius: 50%;
            margin-bottom: 14px;
            box-sizing: border-box;
            border: 3px solid transparent;
            transition: all 0.2s ease;
          }
          .s_name {
            @include sc(24px, #333a48);
            transition: all 0.2s ease;
            @include txtflow();
            max-width: 120px;
          }
          .trigon {
            position: absolute;
            top: -50px;
            right: 0;
            left: 0;
            margin: auto;
            visibility: hidden;
            width: 35px;
            height: 20px;
          }
          .check_active {
            border-color: rgba(93, 90, 237, 0.8);
          }
          .check_txt {
            color: #5d5aed;
          }
          .check_trigon {
            transition: all 0.2s ease;
            visibility: visible;
          }
        }
        .li_transform {
          transform: translateY(-10px);
        }
      }
    }
  }
}

.a-fade-in {
  animation: fade-in 0.4s linear;
}

@include keyframes(fade-in) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

</style>