<template>
  <div class="goodWorks">
    <div class="home_title">
      <span>优秀作品</span>
    </div>
    <div class="works_list">
      <div
        class="works_item"
        v-for="(item, index) in worksData"
        :key="index"
        @click="toDetail(item.id)"
      >
        <div class="cover_box">
          <img class="cover_img" :src="QiNiuPath + item.coverImg" alt />
          <!-- <img 
                class="open_icon" 
                v-if="item.workVideo"
                @click.stop="openViedo(index)" 
                src="../../assets/imgs/video_big_icon.png" alt=""
          >-->
        </div>
        <div class="item_desc">
          <h2 class="title">{{ item.workTitle }}</h2>
          <p class="member">{{ item.author }}</p>
        </div>
      </div>
    </div>

    <VideoPop
      :showVideo="showVideo"
      :fileUrl="fileUrl"
      @closeVideo="closeVideo"
    ></VideoPop>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VideoPop from "@/components/common/videoPop";

export default {
  data() {
    return {
      showVideo: false,
    };
  },
  props: {
    worksData: Array,
  },
  components: { VideoPop },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
  },
  methods: {
    toDetail(id) {
      this.$emit("toDetail", { id });
    },

    openViedo(index) {
      let fileUrl = this.QiNiuPath + this.worksData[index].workVideo;
      this.fileUrl = fileUrl;
      this.showVideo = true;
    },

    closeVideo() {
      this.showVideo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";

.home_title {
  @include sc(40px);
  font-weight: bold;
  text-align: center;
  margin: 0 auto 50px auto;
  width: 1200px;
  position: relative;
  &::after {
    content: "";
    display: block;
    @include wh(70px, 3px);
    background: #5d5aed;
    margin: 7px auto 0 auto;
  }
}

.goodWorks {
  margin-bottom: 45px;
  .works_list {
    width: 1200px;
    @include fj();
    flex-wrap: wrap;
    margin: 0 auto 88px auto;
    .works_item {
      @include wh(580px, 476px, 10px);
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin-bottom: 40px;
      cursor: pointer;
      transition: all 0.3s ease;

      .cover_box {
        @include wh(100%, 386px);
        overflow: hidden;
        position: relative;
        .cover_img {
          @include wh(100%, 386px);
          transition: all 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
        }
        .open_icon {
          @include wh(81px, 69px);
          @include ascenter();
          transition: all 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .item_desc {
        padding: 15px 16px 0 16px;
        .title {
          @include sc(22px, #1c222e);
          font-weight: bold;
          margin-bottom: 5px;
          @include txtflow();
        }
        .member {
          @include sc(16px, #9aa7b9);
        }
      }
    }
  }
}
</style>
