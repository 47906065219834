<template>
  <div class="baseStart">
    <div class="banner">
      <template
        v-if="PortalConfig.navigationMenuList && PortalConfig.navigationMenuList[navMenuSort].operateImg != null"
      >
        <img
          class="banner_img"
          :src="PortalConfig.navigationMenuList[navMenuSort].moduleCode == MODELCODE.BASE && PortalConfig.navigationMenuList[navMenuSort].operateImg != null? QiNiuPath + PortalConfig.navigationMenuList[navMenuSort].operateImg : defaultImg"
          alt
        />
      </template>
    </div>

    <!-- 首次加载没有数据，子组件取不到下标为0的数据 -->
    <div class="cont">
      <StudentStar
        :studentData="baseStarData.studentStyleList"
        v-if="baseStarData.studentStyleList && baseStarData.studentStyleList.length > 0"
      ></StudentStar>
      <TeacherStar
        :teacherData="baseStarData.teacherStyleList"
        @openVideo="openVideo"
        v-if="baseStarData.teacherStyleList && baseStarData.teacherStyleList.length > 0"
      ></TeacherStar>
      <GoodWorks
        :worksData="baseStarData.portalExcellentWorksList"
        @toDetail="toDetail"
        v-if="baseStarData.portalExcellentWorksList && baseStarData.portalExcellentWorksList.length > 0"
      ></GoodWorks>
    </div>

    <!-- 教师风采视频，因为视频弹窗和轮播有冲突所以单独提出 -->
    <VideoPop :showVideo="showVideo" :fileUrl="fileUrl" @closeVideo="closeVideo"></VideoPop>
    
  </div>
</template>

<script>
import GoodWorks from "@/components/baseStar/goodWorks";
import StudentStar from "@/components/baseStar/studentStar";
import TeacherStar from "@/components/baseStar/teacherStar";
import { getBaseStar } from "@/apis/News.js";
import { mapState, mapMutations } from "vuex";
import VideoPop from "@/components/common/videoPop";

export default {
  data() {
    return {
      baseStarData: {},
      playerOptions: {},
      defaultImg: require("../../assets/imgs/base_banner.png"), //默认营销图
      navMenuSort: 1, // 导航排序
      showVideo: false, // 视频
    };
  },
  components: {
    GoodWorks,
    StudentStar,
    TeacherStar,
    VideoPop,
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
      PortalConfig: (state) => state.indexStore.PortalConfig,
      MODELCODE: (state) => state.indexStore.MODELCODE,
    }),
  },
  created() {
    this.getBaseStar();
    this.navMenuSort = Number(localStorage.getItem('sort'));
  },
  mounted() {},
  methods: {
    getBaseStar() {
      getBaseStar({}).then((res) => {
        if (res.data.status == 0) {
          let back = res.data.result;
          this.baseStarData = back;
        }
      });
    },
    // 跳转作品详情
    toDetail(e) {
      let id = e.id;
      this.$router.push({
        path: "/VworksDetail",
        query: { id },
      });
      window.scrollTo(0, 0);
    },

    openVideo(fileUrl) {
      this.fileUrl = fileUrl;
      this.showVideo = true;
    },

    closeVideo() {
      this.showVideo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";

.baseStart {
  .banner {
    @include wh(100%, auto);
    margin-bottom: 45px;
    .banner_img {
      @include wh(100%, auto);
    }
  }

  .cont {
    min-height: 500px;
  }
}
</style>