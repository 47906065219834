<template>
  <div class="teacherStar">
    <div class="home_title">
      <span>教师风采</span>
    </div>
    <div class="cont">
      <div class="banner_cont">
        <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide
            v-for="(item, index) in teacherData"
            :key="item.id"
            style="font-size: 12px;"
             @click.native="openVideo(index)"
          >
            <div class="banner_card" @mouseover="swiperOver" @mouseout="swiperOut">
              <div class="b_card_img" style="position: relative;">
                <img :src="QiNiuPath + item.styleImg" alt/>
                <img
                  class="open_icon"
                  v-if="item.styleVideo"
                  src="../../assets/imgs/video_big_icon.png"
                  alt
                />
              </div>
              <h5>{{item.fullName}}</h5>
              <strong>{{item.personalTitle}}</strong>
              <p>{{item.personalIntroduction}}</p>
            </div>
          </swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination" style="margin-bottom: 50px" @mouseover="swiperPagOver" @mouseout="swiperPagOut"></div> -->
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

let vm = null;

export default {
  data() {
    return {
      swiperOption: {
        //轮播图配置
        slidesPerView: 5,
        centeredSlides: true,
        loop: true, //回路
        pagination: {
          //索引点
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 1800,
          disableOnInteraction: false, // 手动切换之后继续自动轮播
        },
        slideToClickedSlide: true, //设置为true则点击slide会过渡到这个slide。
        // preventLinksPropagation: false,   // 阻止点击事件冒泡
        // on: {
        //   click: function(mySwiper) {
        //     vm.openVideo(vm.mySwiper.realIndex)
        //     // console.log(mySwiper)
        //   }
        // }
      },
    };
  },
  props: {
    teacherData: Array,
  },
  created() {
    vm = this;
  },
  watch: {},
  components: {},
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),

    mySwiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    //划入轮播卡片
    swiperOver() {
      this.mySwiper.autoplay.stop();
    },
    //划出轮播卡片
    swiperOut() {
      this.mySwiper.autoplay.start();
    },
    //划入索引点
    swiperPagOver() {
      // this.mySwiper.autoplay.stop();
    },
    //划出索引点
    swiperPagOut() {
      // this.mySwiper.autoplay.start();
    },

    openVideo(index) {
      console.log(index)
      if(this.teacherData[index].styleVideo) {
        let fileUrl = this.QiNiuPath + this.teacherData[index].styleVideo;
        this.$emit("openVideo", fileUrl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";

.home_title {
  @include sc(40px);
  font-weight: bold;
  text-align: center;
  margin: 0 auto 50px auto;
  width: 1200px;
  position: relative;
  &::after {
    content: "";
    display: block;
    @include wh(70px, 3px);
    background: #5d5aed;
    margin: 7px auto 0 auto;
  }
}

.teacherStar {
  .cont {
    @include wh(100%, 749px);
    min-width: 1200px;
    background: url("../../assets/imgs/teacher_bg.png") no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    .banner_cont {
      width: 1200px;
      margin: 0 auto 0 auto;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .play_video_box {
        @include wh(862px, 543px);
        border: 1px solid red;
        @include ascenter();
        z-index: 1;
      }
    }
  }
}

.banner_card {
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  // padding-top:20px;
  box-shadow: 0px 5px 15px 0px rgba(102, 102, 102, 0.15);
  height: 370px;
  // width: 372px !important;
  // border: 1px solid red;
}
.banner_card > h5 {
  font-weight: 500;
  @include txtflow();
  max-width: 120px;
  margin: 12px auto 4px auto;
  font-size: 1.2rem;
  color: #333a48;
}
.banner_card > strong {
  color: #9aa7b9;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  margin-bottom: 20px;
  @include txtflow();
  max-width: 210px;
}

.b_card_img {
  .open_icon {
    @include wh(81px, 69px);
    @include ascenter();
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 10;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.banner_card > p {
  font-size: 16px;
  line-height: 1.5rem;
  text-align: left;
  margin: 15px;
  color: #9aa7b9;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 0px;
  height: 72px;
}
.b_card_img > img {
  width: 100%;
  height: 185px;
  object-fit: cover;
  // height: 239px;
}
.swiper-container {
  width: 100%;
  height: 70%;
}
.swiper-slide {
  text-align: center;
  // font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.9);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1.3);
  z-index: 89;
}
.swiper-slide-prev {
  transform: scale(1.1);
  z-index: 88;
}
.swiper-slide-next {
  transform: scale(1.1);
  z-index: 88;
}

</style>