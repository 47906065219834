<template>
  <div class="videoPop">
    <a-modal
      :visible="showVideo"
      :footer="null"
      :closable="true"
      :destroyOnClose="true"
      centered
      width="862px"
      @cancel="closeVideo"
    >
      <video-player
        class="video-player-box"
        ref="videoPlayer"
        customEventName="customstatechangedeventname"
        :options="playerOptions"
        :playsinline="true"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
      ></video-player>
      <img
        class="open_icon"
        @click="_openViedo"
        v-if="openIcon"
        src="../../assets/imgs/video_big_icon.png"
        alt
      />
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      playerOptions: {},
      openIcon: true,
    };
  },
  props: {
    showVideo: Boolean,
    fileUrl: String,
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },

  watch: {
    fileUrl(val) {
      this.playerOptions = {
        // videojs options
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: val,
          },
        ],
        // poster: "/static/images/author.jpg",
        width: "804px",
        height: "452px",
        muted: false
      };
    },
  },
  created() {
    // this.openVideo();
  },
  mounted() {},
  methods: {
    onPlayerPlay(event) {
      // console.log(event)
      this.openIcon = false;
    },
    onPlayerPause(event) {
      this.openIcon = true;
      // console.log(event)
    },
    closeVideo() {
      this.$emit("closeVideo");
      this.openIcon = true;
    },
    _openViedo() {
      this.player.play();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin.scss";

.open_icon {
  @include wh(81px, 69px);
  @include ascenter();
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}
</style>